@import "../node_modules/ng-uikit-pro-standard/assets/scss/bootstrap/mixins";
@import "../node_modules/ng-uikit-pro-standard/assets/scss/bootstrap/functions";
@import "../node_modules/ng-uikit-pro-standard/assets/scss/bootstrap/variables";
@import "~ng-uikit-pro-standard/assets/scss/addons-pro/_chat-pro.scss";

/* Parallax video styles*/

$main-dark: #2E2E2E;
$main-light: unquote('#21212181');

body {
    font-family:  century-gothic, sans-serif;
}

@include media-breakpoint-down(xs) {
    .navbar .dropdown-menu {
        color: red;
        position: static !important;
    }
}

.video-parallax {
    clip: rect(0, 100vw, 700px, 0); /* Change second and third value to manipulate the width and height of your video */
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    z-index: -100;
}

.view video {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    min-width: 100vw;
    min-height: 100vh;
    max-width: none;
    max-height: none;
    visibility: visible;
    z-index: -1
}

.jarallax {
    position: relative;
    z-index: 0;
}

.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.century-text {
    font-family: "Century Gothic";
}

.century-bold-text {
    font-family: "Century Gothic";
    font-weight: 600;
}

.navbar {
    background-color: $main-dark;
}

.top-nav-collapse {
    background-color: $main-light;
}

.main-dark {
    background-color: $main-dark;
}

.main-light {
    background-color: $main-light;
}
// @media only screen and (min-width: 768px) {
//     .navbar {
//         background-color: #4285F4;
//     }
// }
@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

@keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    animation-name: autofill;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.card-columns {
    @include media-breakpoint-only(xs) {
        column-count: 2;
    }

    @include media-breakpoint-only(sm) {
        column-count: 2;
    }

    /*@include media-breakpoint-only(lg) {
        column-count: 4;
    }

    @include media-breakpoint-only(xl) {
        column-count: 5;
    }*/
}
