.chat-room .friend-list li {
  border-bottom: 1px solid #e0e0e0;
}
.chat-room .friend-list li:last-of-type {
  border-bottom: none;
}
.chat-room img.avatar {
  height: 3rem;
  width: 3rem;
}
.chat-room .text-small {
  font-size: 0.95rem;
}
.chat-room .text-smaller {
  font-size: 0.75rem;
}
.chat-room.small-chat {
  position: fixed;
  bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 20rem;
}
.chat-room.small-chat.slim {
  height: 3rem;
}
.chat-room.small-chat.slim .icons .feature {
  display: none;
}
.chat-room.small-chat.slim .my-custom-scrollbar {
  display: none;
}
.chat-room.small-chat.slim .card-footer {
  display: none;
}
.chat-room.small-chat .profile-photo img.avatar {
  height: 2rem;
  width: 2rem;
}
.chat-room.small-chat .profile-photo .state {
  position: relative;
  display: block;
  background-color: #007e33;
  height: 0.65rem;
  width: 0.65rem;
  z-index: 2;
  margin-left: 1.35rem;
  left: auto;
  top: -0.5rem;
  border-radius: 50%;
  border: 0.1rem solid #fff;
}
.chat-room.small-chat .profile-photo.message-photo {
  margin-top: 2.7rem;
}
.chat-room.small-chat .heading {
  height: 2.1rem;
}
.chat-room.small-chat .heading .data {
  line-height: 1.5;
}
.chat-room.small-chat .heading .data .name {
  font-size: 0.8rem;
}
.chat-room.small-chat .heading .data .activity {
  font-size: 0.75rem;
}
.chat-room.small-chat .icons {
  padding-top: 0.45rem;
}
.chat-room.small-chat .my-custom-scrollbar {
  position: relative;
  height: 18rem;
  overflow: auto;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body {
  height: 18rem;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body .chat-message .media img {
  width: 3rem;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body .chat-message .media .media-body p {
  font-size: 0.7rem;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body .chat-message .message-text {
  margin-left: 2.47rem;
}
.chat-room.small-chat .card-footer .form-control {
  border: none;
  padding: 0.375rem 0 0.43rem 0;
  font-size: 0.9rem;
}
.chat-room.small-chat .card-footer .form-control:focus {
  box-shadow: none;
}

/* Parallax video styles*/
body {
  font-family: century-gothic, sans-serif;
}

@media (max-width: 575.98px) {
  .navbar .dropdown-menu {
    color: red;
    position: static !important;
  }
}
.video-parallax {
  clip: rect(0, 100vw, 700px, 0);
  /* Change second and third value to manipulate the width and height of your video */
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  z-index: -100;
}

.view video {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  min-width: 100vw;
  min-height: 100vh;
  max-width: none;
  max-height: none;
  visibility: visible;
  z-index: -1;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: "object-fit: cover;";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.century-text {
  font-family: "Century Gothic";
}

.century-bold-text {
  font-family: "Century Gothic";
  font-weight: 600;
}

.navbar {
  background-color: #2E2E2E;
}

.top-nav-collapse {
  background-color: #21212181;
}

.main-dark {
  background-color: #2E2E2E;
}

.main-light {
  background-color: #21212181;
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}
@keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.card-columns {
  /*@include media-breakpoint-only(lg) {
      column-count: 4;
  }

  @include media-breakpoint-only(xl) {
      column-count: 5;
  }*/
}
@media (max-width: 575.98px) {
  .card-columns {
    column-count: 2;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .card-columns {
    column-count: 2;
  }
}